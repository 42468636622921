<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline" ref="searchRef">
        <a-form-model-item 
            prop="financial_account_id" label="财务账户">
          <a-select style="width:320px;" placeholder="请选择账户"
               v-model="financial_account_id"
               @change="changeAccount">
            <a-select-option v-for="(item, index) in financeAccountList"
                 :value="item.financial_account_id"
                 :key="index">{{item.financial_account_name}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item v-if="activeTab != 4" prop="search_date" label="选择时间">
          <a-range-picker
            style="width:300px"
            v-model="search_date"
            valueFormat="YYYY-MM-DD"
            @change="onChangeDate"
          />
        </a-form-model-item>
        <a-form-model-item v-if="activeTab == 4" prop="end_date" label="截止时间">
          <a-date-picker
            style="width:200px" placeholder="截止日期"
            v-model="end_date"
            value-format="YYYY-MM-DD"
            @change="onChangeEndDate"
          />
        </a-form-model-item>
        <a-form-model-item>
          <a-button type="primary" style="width:100px;"
          	:loading="isLoading" @click="handlerSearch()">查询</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>

    <div class="mt-2">
      <a-tabs type="card" class="w-full" @change="changeTabs" :activeKey="activeTab">
        <a-tab-pane :key="0" tab="收款订单"></a-tab-pane>
        <a-tab-pane :key="1" tab="交付订单"></a-tab-pane>
        <a-tab-pane :key="2" tab="钱包数据"></a-tab-pane>
        <a-tab-pane :key="3" tab="内部结算"></a-tab-pane>
        <a-tab-pane :key="4" tab="订单预收款"></a-tab-pane>
      </a-tabs>
    </div>
    
    <template v-if="activeTab==0">
			<!-- 预订单的分布 -->
			<div class="flex justify-between mt-6">
				<div class="flex items-center">
					<h4 class="text-lg font-bold">本账户收款的订单 - 下单</h4>
					<div class="text-sm mb-2">（在此期间下单支付到本账户）</div>
				</div>
				<a-button @click="downloadOrder">下载本页</a-button>
			</div>
			<!-- 科技公司 -->
			<div v-if="financial_account_id==1">
				<div class="text-sm mt-1 mb-2">订单来源分布（单位:笔）</div>
				<base-table style="max-width:520px;"
					:columnsData="columns_order_source"
					:tableData="tableData_source"
				></base-table>
				<div class="text-sm mt-4 mb-2">支付方式分布（单位:元）</div>
				<base-table
					:columnsData="columns_order_pay"
					:tableData="tableData_pay"
				></base-table>
			</div>
			<!-- 城市公司 -->
			<div v-else>
				<div class="text-sm mt-1 mb-2">订单来源分布（单位:笔）</div>
				<base-table
					:columnsData="columns_order_source"
					:tableData="tableData_source"
				></base-table>
				<div class="text-sm mt-4 mb-2">支付方式分布（单位:元）</div>
				<base-table
					:columnsData="columns_order_pay"
					:tableData="tableData_pay"
				></base-table>
			</div>
				
			<a-divider />

			<div class="flex items-center">
				<h4 class="text-lg font-bold">本账户收款的订单 - 完成交付</h4>
				<div class="text-sm mb-2">（下单支付到本账户，在此期间完成交付的订单）</div>
				<!-- *不一定由本账户交付、内部结算给交付方 -->
			</div>
			<div>
				<!-- <div class="text-sm mt-4 mb-2"><b>预订单总览</b>（根据下单时间筛选）</div>
				<base-table style="max-width:320px;"
					:columnsData="columns_order"
					:tableData="tableData_order"
				></base-table> -->
				<!-- <div class="text-sm mt-4 mb-2"><b>交付完成订单</b>（根据订单完成时间筛选）</div> -->
				<base-table style="max-width:1200px;"
					:columnsData="columns_complete"
					:tableData="tableData_complete"
				></base-table>
			</div>
			<a-divider />

			<div class="flex items-center">
				<h4 class="text-lg font-bold">本账户收款的订单 - 售后退款</h4>
				<div class="text-sm mb-2">（下单支付到本账户，在此期间售后退款的订单）*本账户退款给用户，通过内部结算由交付方承担</div>
			</div>
			<div class1="ml-8">
				<!-- <div class="text-sm mt-4 mb-2"><b>订单售后退款</b>（根据退款时间筛选）</div> -->
				<base-table style="max-width:1200px;"
					:columnsData="columns_refund"
					:tableData="tableData_refund"
				></base-table>
			</div>
    </template>

    <template v-if="activeTab==1">
			<!-- 交付的分布 -->
			<div class="flex justify-between mt-6">
				<div class="flex items-center">
					<h4 class="text-lg font-bold">本账户交付的订单 - 交付完成</h4>
					<div class="text-sm mb-2">（本账户在此期间完成交付的订单）</div>
				</div>
				<a-button @click="downloadCompleteOrder">下载本页</a-button>
			</div>
			<div class1="ml-8">
				<!-- <div class="text-sm mt-4 mb-2"><b>完成订单</b>（根据订单完成时间筛选）</div> -->
				<base-table style="max-width:1200px;"
					:columnsData="columns_complete2"
					:tableData="tableData_complete2"
				></base-table>
			</div>

			<div class="flex items-center mt-6">
				<h4 class="text-lg font-bold">本账户交付的订单 - 售后退款</h4>
				<div class="text-sm mb-2">（本账户交付后、在此期间发生售后退款的订单）*应该通过内部结算由本账户承担退款</div>
			</div>
			<div class1="ml-8">
				<!-- <div class="text-sm mt-4 mb-2"><b>应承担的售后退款</b>（根据退款时间筛选）</div> -->
				<base-table style="max-width:1200px;"
					:columnsData="columns_refund2"
					:tableData="tableData_refund2"
				></base-table>
			</div>
    </template>

    <template v-if="activeTab==2">
    	<div class="flex justify-between items-center mt-2">
				<div class="text-sm">钱包账户（包含途中的礼品卡）</div>
				<div>
					<a-button @click="downloadVipcard">下载本页</a-button>
					<a-button class="p-0 ml-2" type="link" @click="exportVipcard">导出明细</a-button>
				</div>
			</div>
    	<base-table style="max-width:420px;"
				:columnsData="columns_vipcard_period"
				:tableData="tableData_vipcard"
			></base-table>

			<div class="text-sm mt-4 mb-2">充值和退款</div>
			<base-table style="max-width:560px;"
				:columnsData="columns_vipcard_account"
				:tableData="tableData_vipcard"
			></base-table>
			
			<div class="text-sm mt-4 mb-2">消费和退款</div>
			<base-table style="max-width:560px;"
				:columnsData="columns_vipcard_consume"
				:tableData="tableData_vipcard"
			></base-table>
    </template>

    <!-- <template v-if="activeTab==2">
			<div class="flex justify-between items-center mt-2">
				<div class="text-sm">充值到本账户</div>
				<a-button @click="exportVipcard">导出</a-button>
			</div>
			<base-table style="max-width:400px;"
				:columnsData="columns_vipcard_recharge"
				:tableData="tableData_vipcard"
			></base-table>
			<div class="text-sm mt-4 mb-2">在线订单消费（从本账户扣除）</div>
			<base-table style="max-width:400px;"
				:columnsData="columns_vipcard_online"
				:tableData="tableData_vipcard"
			></base-table>
			<div class="text-sm mt-4 mb-2">线下到店核销（从本账户扣除）</div>
			<base-table style="max-width:400px;"
				:columnsData="columns_vipcard_offline"
				:tableData="tableData_vipcard"
			></base-table>
    </template> -->

    <template v-if="activeTab==3">
			<div class="flex justify-between items-center mt-2 mb-2">
				<div class="text-sm">本账户和“目标账户”之间的内部结算（单位：元） * 支:付给结算账户，收:从目标账户收取
					<!-- 旺店通默认账户 -->
					<a v-if="financial_account_id==2" type="link" class="ml-4" @click="handlerSettleDetail">明细</a>
				</div>
				<a-button @click="downloadSettle">下载本页</a-button>
			</div>
			<table class="settle-table" id="settle-table">
    		<thead>
    			<tr>
    				<td rowspan="2">目标账户</td>
    				<td colspan="2">支付到本账户、目标账户交付<span class="link-btn ml-2" 
    						@click="handlerExportFlow(1)">明细</span></td>
    				<td colspan="2">支付到目标账户、本账户交付<span class="link-btn ml-2" 
    						@click="handlerExportFlow(2)">明细</span></td>
    				<td colspan="2">本账户钱包到目标账户消费<span class="link-btn ml-2" 
    						@click="handlerExportFlow(5)">明细</span></td>
    				<td colspan="2">目标账户钱包到本账户消费<span class="link-btn ml-2" 
    						@click="handlerExportFlow(6)">明细</span></td>
    				<td colspan="2">本账户券到目标账户消费</td>
    				<td colspan="2">目标账户券到本账户消费</td>
    				<td rowspan="2">合计收入<br>从目标账户</td>
    			</tr>
    			<tr>
    				<td>交付结算(支)</td>
    				<td>售后退款(收)</td>
    				<td>交付结算(收)</td>
    				<td>售后退款(支)</td>
    				<td>钱包消费(支)</td>
    				<td>消费退款(收)</td>
    				<td>钱包消费(收)</td>
    				<td>消费退款(支)</td>
    				<td>券消费(支)</td>
    				<td>券退款(收)</td>
    				<td>券消费(收)</td>
    				<td>券退款(支)</td>
    			</tr>
    		</thead>
    		<tbody v-if="!tableData_settle_new.length">
    			<tr>
    				<td colspan="9"><div class="no-data">暂无数据</div></td>
    			</tr>
    		</tbody>
    		<tbody v-if="tableData_settle_new.length">
    			<tr v-for="(item,index) in tableData_settle_new"
    						:key="index"
              	:value="item">
              <td>{{ item.financial_account_name }}</td>
              <td>{{ formatEnMoney(item.amount_1,  false, true) }}</td>
              <td>{{ formatEnMoney(item.amount_3,  false, false) }}</td>
              <td>{{ formatEnMoney(item.amount_2,  false, false) }}</td>
              <td>{{ formatEnMoney(item.amount_4,  false, true) }}</td>
              <td>{{ formatEnMoney(item.amount_5,  false, true) }}</td>
              <td>{{ formatEnMoney(item.amount_6,  false, false) }}</td>
              <td>{{ formatEnMoney(item.amount_7,  false, false) }}</td>
              <td>{{ formatEnMoney(item.amount_8,  false, true) }}</td>
              <td>{{ formatEnMoney(item.amount_9,  false, true) }}</td>
              <td>{{ formatEnMoney(item.amount_10, false, false) }}</td>
              <td>{{ formatEnMoney(item.amount_11, false, false) }}</td>
              <td>{{ formatEnMoney(item.amount_12, false, true) }}</td>
              <td>{{ formatEnMoney(item.total_amount) }}</td>
          </tr>
    		</tbody>
    	</table>
    </template>


    <template v-if="activeTab==4">
			<div class="flex justify-between items-center mt-2">
				<div class="text-sm">
					<span v-if="end_date">截至 {{end_date}}日 24时那一刻，</span>
					<span v-else>截至当前，</span>
					本财务账户的订单预收款（*已收款未交付）
				</div>
				<a-button class="p-0" type="link" @click="exportWaiting">导出明细</a-button>
			</div>
			<div>
				<base-table style="max-width:500px;"
					:columnsData="columns_waiting"
					:tableData="tableData_waiting"
				></base-table>
			</div>
    </template>


    <div class="mb-6"></div>

		<!-- 抽屉展示 旺店通默认账户明细 -->
    <a-drawer title="快递子订单的待拆分明细"
              :get-container="false"
              :wrap-style="{ position: 'absolute' }"
              width="73vw"
              placement="left"
              destroyOnClose
              :visible="isShowWdtDetail"
              @close="isShowWdtDetail = false">
      <wdtDetail :searchTime="search_date"
                   v-if="isShowWdtDetail"></wdtDetail>
    </a-drawer>
  </div>
</template>

<script>
import pageData from "./columns"
import { 
	financeAccountOrder, 
	financeAccountVipcard, 
	financeAccountSettle,
	financeAccountWaitingOrder,
	financeAccountExportSettle1,
	financeAccountExportSettle2,
	// financeAccountExportSettle3,
	// financeAccountExportSettle4,
	financeAccountExportSettle5,
	financeAccountExportSettle6,
	// financeAccountWDTSettleDetail
	financeAccountExportVipCardFlow,
} from "@/api/finance/finance.js"
import { 
	exportFinancialWaitingOrder,
} from "@/api/finance/analysis.js"

import wdtDetail from './wdt-detail.vue'
import { formatEnMoney } from "@/utils/index.js"

export default {
  components: { wdtDetail },
  data() {
    return {
			formatEnMoney,

			activeTab: 0,
			search_date: [],
			end_date: "",
			financial_account_id: '',
			financeAccountList: [],

			isShowWdtDetail: false,

			...pageData,

			columns_order_source: [],
			columns_order_pay: [],

			tableData_order: [],
			tableData_order2: [],
			columns_order: [
				{
					title: "有效订单数",
					dataIndex: "place_order_count",
					align: "center",
				},
				{
					title: "有效订单总金额(元)",
					dataIndex: "order_amount",
					align: "center",
					slots: {
						customRender: "order_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],
			tableData_complete: [],
			columns_complete: [
				{
					title: "交付订单数",
					dataIndex: "order_count",
					align: "center",
					width: 160,
				},
				{
					title: "交付的订单总额(元)",
					dataIndex: "order_amount",
					align: "center",
					width: 160,
					slots: {
						customRender: "order_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "本财务账户交付订单数",
					dataIndex: "suborder_count",
					align: "center",
				},
				{
					title: "本财务账户交付总额(元)",
					dataIndex: "suborder_amount",
					align: "center",
					slots: {
						customRender: "suborder_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "其他财务账户交付订单数",
					dataIndex: "other_suborder_count",
					align: "center",
				},
				{
					title: "其他财务账户交付总额(元)",
					dataIndex: "other_suborder_amount",
					align: "center",
					slots: {
						customRender: "other_suborder_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],

			tableData_refund: [],
			columns_refund: [
				{
					title: "售后退款订单数",
					dataIndex: "order_count",
					align: "center",
					width: 160,
				},
				{
					title: "售后退款总额(元)",
					dataIndex: "order_amount",
					align: "center",
					width: 160,
					slots: {
						customRender: "order_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "本财务账户交付的退款订单数",
					dataIndex: "suborder_count",
					align: "center",
				},
				{
					title: "本财务账户交付的退款总额(元)",
					dataIndex: "suborder_amount",
					align: "center",
					slots: {
						customRender: "suborder_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "其他财务交付的退款订单数",
					dataIndex: "other_suborder_count",
					align: "center",
				},
				{
					title: "其他财务交付的退款总额(元)",
					dataIndex: "other_suborder_amount",
					align: "center",
					slots: {
						customRender: "other_suborder_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],

			// 我交付
			tableData_complete2: [],
			columns_complete2: [
				{
					title: "本财务账户交付总订单数",
					dataIndex: "suborder_count",
					align: "center",
					width: 160,
				},
				{
					title: "本财务账户交付总额(元)",
					dataIndex: "suborder_amount",
					align: "center",
					width: 160,
					slots: {
						customRender: "suborder_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "其中本财务账户收款订单数",
					dataIndex: "order_suborder_count",
					align: "center",
				},
				{
					title: "其中本财务账户收款的总额(元)",
					dataIndex: "order_suborder_amount",
					align: "center",
					slots: {
						customRender: "order_suborder_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "其中其他财务收款的订单数",
					dataIndex: "other_order_suborder_count",
					align: "center",
				},
				{
					title: "其中其他财务收款的总额(元)",
					dataIndex: "other_order_suborder_amount",
					align: "center",
					slots: {
						customRender: "other_order_suborder_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],

			tableData_refund2: [],
			columns_refund2: [
				{
					title: "售后退款订单数",
					dataIndex: "suborder_count",
					align: "center",
					width: 160,
				},
				{
					title: "售后退款总额(元)",
					dataIndex: "suborder_amount",
					align: "center",
					width: 160,
					slots: {
						customRender: "suborder_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "下单到本主体的订单数",
					dataIndex: "order_suborder_count",
					align: "center",
				},
				{
					title: "下单到本主体的退款总额(元)",
					dataIndex: "order_suborder_amount",
					align: "center",
					slots: {
						customRender: "order_suborder_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				{
					title: "下单到其他主体的退款订单数",
					dataIndex: "other_order_suborder_count",
					align: "center",
				},
				{
					title: "下单到其他主体的退款总额(元)",
					dataIndex: "other_order_suborder_amount",
					align: "center",
					slots: {
						customRender: "other_order_suborder_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
			],

			tableData_pay: [],

			tableData_source: [],

			tableData_vipcard: [],
			columns_vipcard_period: [
				{
					title: "期初(元)",
					dataIndex: "time_start_amount",
					align: "center",
				},
				{
					title: "期末(元)",
					dataIndex: "time_end_amount",
					align: "center",
				},
				{
					title: "期间变化(元)",
					dataIndex: "time_delta_amount",
					align: "center",
				},
				// {
				// 	title: "钱包充值(元)",
				// 	dataIndex: "vip_card_recharge_amount",
				// 	align: "center",
				// },
				// {
				// 	title: "钱包退余额(元)",
				// 	dataIndex: "vip_card_refund_amount",
				// 	align: "center",
				// },
				// {
				// 	title: "礼品卡售卖(元)",
				// 	dataIndex: "gift_card_recharge_amount",
				// 	align: "center",
				// },
				// {
				// 	title: "礼品卡退款(元)",
				// 	dataIndex: "gift_card_refund_amount",
				// 	align: "center",
				// },
			],
			columns_vipcard_account: [
				// {
				// 	title: "期初(元)",
				// 	dataIndex: "time_start_amount",
				// 	align: "center",
				// },
				// {
				// 	title: "期末(元)",
				// 	dataIndex: "time_end_amount",
				// 	align: "center",
				// },
				{
					title: "钱包直充(元)",
					dataIndex: "vip_card_recharge_amount",
					align: "center",
				},
				{
					title: "钱包退余额(元)",
					dataIndex: "vip_card_refund_amount",
					align: "center",
				},
				{
					title: "礼品卡售卖(元)",
					dataIndex: "gift_card_recharge_amount",
					align: "center",
				},
				{
					title: "礼品卡退款(元)",
					dataIndex: "gift_card_refund_amount",
					align: "center",
				},
			],
			columns_vipcard_consume: [
				{
					title: "线上消费(元)",
					dataIndex: "online_consumption_amount",
					align: "center",
				},
				{
					title: "线上消费退款(元)",
					dataIndex: "online_refund_amount",
					align: "center",
				},
				{
					title: "线下消费(元)",
					dataIndex: "offline_consumption_amount",
					align: "center",
				},
				{
					title: "线下消费退款(元)",
					dataIndex: "offline_refund_amount",
					align: "center",
				},
			],

			columns_vipcard_recharge: [
				{
					title: "充值笔数",
					dataIndex: "recharge_order_count",
					align: "center",
				},
				{
					title: "充值金额(元)",
					dataIndex: "recharge_denomination",
					align: "center",
					slots: {
						customRender: "recharge_denomination",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				// {
				// 	title: "充值支付金额(元)",
				// 	dataIndex: "recharge_pay_amount",
				// 	align: "center",
				// 	slots: {
				// 		customRender: "recharge_pay_amount",
				// 	},
				// 	slotsType: "format",
				// 	slotsFunc: (val) => {return formatEnMoney(val)},
				// },
			],

			columns_vipcard_online: [
				{
					title: "在线消费笔数",
					dataIndex: "online_consumption_order_count",
					align: "center",
				},
				{
					title: "在线消费金额(元)",
					dataIndex: "online_consumption_amount",
					align: "center",
					slots: {
						customRender: "online_consumption_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				// {
				// 	title: "对应充值金额(元)",
				// 	dataIndex: "online_consumption_recharge_discount_amount",
				// 	align: "center",
				// 	slots: {
				// 		customRender: "online_consumption_recharge_discount_amount",
				// 	},
				// 	slotsType: "format",
				// 	slotsFunc: (val) => {return formatEnMoney(val)},
				// },
			],

			columns_vipcard_offline: [
				{
					title: "线下消费笔数",
					dataIndex: "offline_consumption_order_count",
					align: "center",
				},
				{
					title: "线下消费金额(元)",
					dataIndex: "offline_consumption_amount",
					align: "center",
					slots: {
						customRender: "offline_consumption_amount",
					},
					slotsType: "format",
					slotsFunc: (val) => {return formatEnMoney(val)},
				},
				// {
				// 	title: "对应充值金额(元)",
				// 	dataIndex: "offline_consumption_recharge_discount_amount",
				// 	align: "center",
				// 	slots: {
				// 		customRender: "offline_consumption_recharge_discount_amount",
				// 	},
				// 	slotsType: "format",
				// 	slotsFunc: (val) => {return formatEnMoney(val)},
				// },
			],

			tableData_settle_new: [],

			columns_waiting: [
				{
					title: "订单数",
					dataIndex: "order_count",
					align: "center",
				},
				{
					title: "预收款总额(元)",
					dataIndex: "order_pay_flow_amount",
					align: "center",
				}
			],
			tableData_waiting: [],

			isLoading: false,
			loadingHideCallback: null,

    }
  },
  mounted() {
    this.search_date = [
			this.getThisMonthFirstDay(),
			this.getToday(),
		]

    const list = this.$store.getters['authAccountList']
		if(list && list.length) {
			this.financeAccountList = list
			this.financial_account_id = list[0].financial_account_id
			// this.handlerSearch()
		}
  },
  methods: {
    changeAccount(){
			// this.handlerSearch(true)
    },

    changeTabs(v){
      this.activeTab = v
      // this.handlerSearch(true)
    },

    // 搜索
		handlerSearch(ignoreLoading) {
			if(!ignoreLoading){
				if(this.isLoading) return
			}

			if(this.activeTab != 4){
				if(this.search_date.length < 2){
					this.$message.warning("请选择时间")
					return
				}
				if(new Date(this.search_date[1]).getTime() - new Date(this.search_date[0]).getTime() > 31 * 24 * 3600 * 1000){
						this.$message.warning("完成时间跨度不能超一个月")
						return
					}
			}
			
			if(this.activeTab == 0){
				this.columns_order_source = this.columns_source_simple.filter(el=>{
					if(this.financial_account_id == 1){
						return el.isTechComp != 1
					}else{
						return el.isTechComp != 2
					}
				})
				this.columns_order_pay = this.columns_pay_simple.filter(el=>{
					if(this.financial_account_id == 1){
						return el.isTechComp != 1
					}else{
						return el.isTechComp != 2
					}
				})
      }

      if(this.activeTab == 0){
				this.initDataOrder()
      }
      if(this.activeTab == 1){
				this.initCompleteOrder()
      }
      if(this.activeTab == 2){
				this.initDataVipcard()
      }
      if(this.activeTab == 3){
				this.initDataSettle()
      }
      if(this.activeTab == 4){
				this.initDataWaitingOrder()
      }
		},

		onChangeDate(){
			if (this.search_date.length < 2) {
				return
			}
			// this.handlerSearch()
		},
		onChangeEndDate(){
			// this.handlerSearch()
		},

		async initDataOrder() {
			this.showLoading()
			this.tableData_order = []
			this.tableData_complete = []
			this.tableData_refund = []
			this.tableData_source = []
			this.tableData_pay = []
			const params = {
				financial_account_id: this.financial_account_id,
				time_start: this.search_date[0] + ' 00:00:00',
				time_end:   this.search_date[1] + ' 23:59:59',
				type: 1
			}
			const { data, code } = await financeAccountOrder(params)
			this.hideLoading()
			if (code === 0) {
				// 全部
				data.order_source_data['0'] = data.place_order_count
				data.order_amount_data['0'] = data.order_amount
				// 第三方汇总
				let third_plat = 0
				Object.keys(data.order_source_data).filter(source=>parseInt(source)>=16).map(key=>{
					third_plat += data.order_source_data[key]
				})
				data.order_source_data['third_plat'] = third_plat
				// 支付方式分布：第三方明细
				for(let j in data.order_amount_data['50_amount_data']){
					data.order_amount_data['50_amount_data_'+j] = formatEnMoney(data.order_amount_data['50_amount_data'][j])
				}
				// 支付方式分布：金额格式化
				for(let i in data.order_amount_data){
					data.order_amount_data[i] = formatEnMoney(data.order_amount_data[i])
				}

				this.tableData_order = [data]
				this.tableData_complete = [data.finish_order_data]
				this.tableData_refund = [data.refund_order_data]

				this.tableData_source = [data.order_source_data]
				this.tableData_pay = [data.order_amount_data]
			}
    },

    async initCompleteOrder() {
			this.showLoading()
			this.tableData_order2 = []
			this.tableData_complete2 = []
			this.tableData_refund2 = []
			const params = {
				financial_account_id: this.financial_account_id,
				time_start: this.search_date[0] + ' 00:00:00',
				time_end:   this.search_date[1] + ' 23:59:59',
				type: 2
			}
			const { data, code } = await financeAccountOrder(params)
			this.hideLoading()
			if (code === 0) {
				this.tableData_order2 = [data]
				this.tableData_complete2 = [data.finish_order_data_2]
				this.tableData_refund2 = [data.refund_order_data_2]
			}
    },
    downloadOrder(){
			let keyArr, valArr
			let data = this.tableData_order[0]

			let list1 = []
					list1.push([
						"期间预订单"
					])
					list1.push([
						"",
						"预订单来源分布"
					])
					keyArr = [""]
					valArr = [""]
					this.columns_order_source.map(el=>{
						keyArr.push(el.title)
						valArr.push(data.order_source_data[el.dataIndex])
					})
					list1.push(keyArr)
					list1.push(valArr)
					list1.push([ ])

					list1.push([
						"",
						"预订单支付方式分布"
					])
					keyArr = [""]
					valArr = [""]
					this.columns_order_pay.map(el=>{
						keyArr.push(el.title)
						valArr.push(data.order_amount_data[el.dataIndex])
					})
					list1.push(keyArr)
					list1.push(valArr)
					list1.push([ ])

					list1.push([
						"期间完成订单"
					])
					keyArr = [""]
					valArr = [""]
					this.columns_complete.map(el=>{
						keyArr.push(el.title)
						valArr.push(data.finish_order_data[el.dataIndex])
					})
					list1.push(keyArr)
					list1.push(valArr)
					list1.push([ ])

					list1.push([
						"期间订单售后退款"
					])
					keyArr = [""]
					valArr = [""]
					this.columns_refund.map(el=>{
						keyArr.push(el.title)
						valArr.push(data.refund_order_data[el.dataIndex])
					})
					list1.push(keyArr)
					list1.push(valArr)
      const XLSX = require('xlsx')
      let wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(list1), "本账户的预订单")

      XLSX.writeFile(wb, "财务账户-本账户的预订单.xlsx")
    },
    downloadCompleteOrder(){
			let keyArr, valArr
			let data = this.tableData_order2[0]

			let list3 = []
					list3.push([
						"完成订单"
					])
					keyArr = [""]
					valArr = [""]
					this.columns_complete2.map(el=>{
						keyArr.push(el.title)
						valArr.push(data.finish_order_data_2[el.dataIndex])
					})
					list3.push(keyArr)
					list3.push(valArr)
					list3.push([ ])

					list3.push([
						"应承担的售后退款"
					])
					keyArr = [""]
					valArr = [""]
					this.columns_refund2.map(el=>{
						keyArr.push(el.title)
						valArr.push(data.refund_order_data_2[el.dataIndex])
					})
					list3.push(keyArr)
					list3.push(valArr)
			
      const XLSX = require('xlsx')
      let wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(list3), "本账户交付的订单")

      XLSX.writeFile(wb, "财务账户-本账户交付的订单.xlsx")
    },

    downloadVipcard(){
			let keyArr, valArr
			let data = this.tableData_vipcard[0]

			let list1 = []
					list1.push([
						"钱包账户"
					])
					keyArr = [""]
					valArr = [""]
					this.columns_vipcard_period.map(el=>{
						keyArr.push(el.title)
						valArr.push(data[el.dataIndex])
					})
					list1.push(keyArr)
					list1.push(valArr)
					list1.push([ ])

					list1.push([
						"充值与退款"
					])
					keyArr = [""]
					valArr = [""]
					this.columns_vipcard_account.map(el=>{
						keyArr.push(el.title)
						valArr.push(data[el.dataIndex])
					})
					list1.push(keyArr)
					list1.push(valArr)
					list1.push([ ])

					list1.push([
						"消费与退款"
					])
					keyArr = [""]
					valArr = [""]
					this.columns_vipcard_consume.map(el=>{
						keyArr.push(el.title)
						valArr.push(data[el.dataIndex])
					})
					list1.push(keyArr)
					list1.push(valArr)
			
      const XLSX = require('xlsx')
      let wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(list1), "钱包和礼品卡")

      XLSX.writeFile(wb, "财务账户-钱包和礼品卡.xlsx")
    },
    exportVipcard(){
    	if(this.search_date.length < 2){
				this.$message.warning("请选择时间")
				return
			}
			if(new Date(this.search_date[1]).getTime() - new Date(this.search_date[0]).getTime() > 31 * 24 * 3600 * 1000){
					this.$message.warning("完成时间跨度不能超一个月")
					return
				}
			const params = {
				financial_account_id: this.financial_account_id,
				time_start: this.search_date[0] + ' 00:00:00',
				time_end:   this.search_date[1] + ' 23:59:59'
			}
			
			financeAccountExportVipCardFlow(params)
    },

   //  exportVipcard(){
			// let keyArr, valArr
			// let data = this.tableData_vipcard[0]

			// let list1 = []
			// 		list1.push([
			// 			"充值"
			// 		])
			// 		keyArr = [""]
			// 		valArr = [""]
			// 		this.columns_vipcard_recharge.map(el=>{
			// 			keyArr.push(el.title)
			// 			valArr.push(data[el.dataIndex])
			// 		})
			// 		list1.push(keyArr)
			// 		list1.push(valArr)
			// 		list1.push([ ])

			// 		list1.push([
			// 			"在线消费"
			// 		])
			// 		keyArr = [""]
			// 		valArr = [""]
			// 		this.columns_vipcard_online.map(el=>{
			// 			keyArr.push(el.title)
			// 			valArr.push(data[el.dataIndex])
			// 		})
			// 		list1.push(keyArr)
			// 		list1.push(valArr)
			// 		list1.push([ ])

			// 		list1.push([
			// 			"线下消费"
			// 		])
			// 		keyArr = [""]
			// 		valArr = [""]
			// 		this.columns_vipcard_offline.map(el=>{
			// 			keyArr.push(el.title)
			// 			valArr.push(data[el.dataIndex])
			// 		})
			// 		list1.push(keyArr)
			// 		list1.push(valArr)
			
   //    const XLSX = require('xlsx')
   //    let wb = XLSX.utils.book_new()

   //    XLSX.utils.book_append_sheet(wb, XLSX.utils.aoa_to_sheet(list1), "会员卡")

   //    XLSX.writeFile(wb, "财务账户-会员卡数据.xlsx")
   //  },

    downloadSettle(){
      const XLSX = require('xlsx')

      let wb = XLSX.utils.book_new()

      XLSX.utils.book_append_sheet(wb, XLSX.utils.table_to_sheet(document.querySelector("#settle-table")), "内部结算(单位元)")

      XLSX.writeFile(wb, "财务账户-内部结算.xlsx")
    },

    exportWaiting(){
    	exportFinancialWaitingOrder({
  			financial_account_id: this.financial_account_id,
				time_end:   this.end_date ? (this.end_date + ' 23:59:59') : ''
    	})
    },

    async initDataVipcard() {
    	this.showLoading()
    	this.tableData_vipcard = []
			const params = {
				financial_account_id: this.financial_account_id,
				time_start: this.search_date[0] + ' 00:00:00',
				time_end:   this.search_date[1] + ' 23:59:59'
			}
			const { data, code } = await financeAccountVipcard(params)
			this.hideLoading()
			if (code === 0) {
				data.time_delta_amount = formatEnMoney(Number(data.time_end_amount) - Number(data.time_start_amount))
				this.tableData_vipcard = [data]
			}
    },

    async initDataSettle() {
    	this.showLoading()
    	this.tableData_settle_new = []
			const params = {
				financial_account_id: this.financial_account_id,
				time_start: this.search_date[0] + ' 00:00:00',
				time_end:   this.search_date[1] + ' 23:59:59'
			}
			const { data, code } = await financeAccountSettle(params)
			this.hideLoading()

			if (code === 0) {
				// this.tableData_settle = data.list
				this.tableData_settle_new = data.list.map(item=>{
					item.amount_5 = item.amount_5.amount
					item.amount_6 = item.amount_6.amount
					item.amount_7 = item.amount_7.amount
					item.amount_8 = item.amount_8.amount
					item.amount_11 = item.amount_9_1
					item.amount_12 = item.amount_10_1

					console.log(item, item.amount_9_1, item.amount_10_1)

					let total = -Number(item.amount_1)  + Number(item.amount_3)
											+Number(item.amount_2)  - Number(item.amount_4)  
										  -Number(item.amount_5)  + Number(item.amount_6)
											+Number(item.amount_7)  - Number(item.amount_8)
											-Number(item.amount_9)  + Number(item.amount_10)
											+Number(item.amount_11) - Number(item.amount_12)

					item.total_amount = formatEnMoney(total)
					// item.amount_3 = formatEnMoney(item.amount_3, false, true)
					// item.amount_4 = formatEnMoney(item.amount_4, false, true)
					// item.amount_5 = formatEnMoney(item.amount_5, false, true)
					// item.amount_8 = formatEnMoney(item.amount_8, false, true)
					return item
				})
			}
    },

    async initDataWaitingOrder(){
    	this.showLoading()
    	this.tableData_waiting = []
    	const params = {
				financial_account_id: this.financial_account_id,
				time_end:   this.end_date ? (this.end_date + ' 23:59:59') : ''
			}
			const { data, code } = await financeAccountWaitingOrder(params)
			this.hideLoading()
			if (code === 0) {
				this.tableData_waiting = [data]
			}
    },

    showLoading(){
    	this.hideLoading()
    	this.isLoading = true
    	this.loadingHideCallback = this.$message.loading("加载中...", 0)
    },
    hideLoading(){
    	if(this.loadingHideCallback){
    		this.loadingHideCallback()
    		this.loadingHideCallback = null
    	}
    	this.isLoading = false
    },

		handlerSettleDetail(){
			this.isShowWdtDetail = true
		},

		handlerExportFlow(type){
			let params = {
				financial_account_id: this.financial_account_id,
				time_start: this.search_date[0] + ' 00:00:00',
				time_end:   this.search_date[1] + ' 23:59:59',
			}
			
			if(type == 1) financeAccountExportSettle1(params)
			if(type == 2) financeAccountExportSettle2(params)
			// if(type == 3) financeAccountExportSettle3(params)
			// if(type == 4) financeAccountExportSettle4(params)
			if(type == 5) financeAccountExportSettle5(params)
			if(type == 6) financeAccountExportSettle6(params)
		},

		// -----------
		getLastMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			// 1月：上一个月 是去年12月
			if(month == 0){
				month = 11
				year = year-1
			}else{
				month = month-1
			}
			return this.formatDate(new Date(year, month, 1))
    },
    getLastMonthLastDay(){
			let date = new Date()
			date.setTime(date.getTime() - date.getDate()*24*3600*1000)

			return this.formatDate(date)
    },
    getThisMonthFirstDay(){
			const today = new Date()
			let month = today.getMonth()
			let year = today.getFullYear()
			return this.formatDate(new Date(year, month, 1))
    },
    getToday(){
			let date = new Date()
			return this.formatDate(date)
    },

    formatDate(date){
			let year = date.getFullYear()
			let month = date.getMonth()
			let day = date.getDate()
			month = month+1
			if(month < 10){
				month = "0"+month
			}
			if(day < 10){
				day = "0"+day
			}
			return year+"-"+month+"-"+day
    },

    renderContent({ text, index }){
		  const obj = {
		    children: text,
		    props: {},
		  };

		  if (index === 4) {
		    obj.props.colSpan = 0;
		  }

		  return obj;
		}

  },
}
</script>

<style lang="less">
.settle-table{
	width: 100%;
	border: 1px solid #e8e8e8;
	border-collapse: collapse;
	text-align: center;
	thead{
		background: #fafafa;
		color: rgba(0, 0, 0, 0.85);
    font-weight: 500;
		td{
			padding: 10px 8px;
		}
	}
	td{
		border: 1px solid #e8e8e8;
		padding: 8px;
		line-height: 20px;
	}
	.no-data{
		text-align: center;
		padding: 40px;
	}
	.link-btn{
		cursor: pointer;
		color: #1890ff;
		font-weight: 400;
	}
}
</style>